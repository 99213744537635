/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Entremalhas BFF
 * BFF for Entremalhas
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import type {
  DeleteCustomerFavoriteProductsBody,
  GetCustomer200,
  GetCustomerCart200,
  GetCustomerCartParams,
  GetCustomerFavorites200Item,
  PatchCustomerBody,
  PostAuthLogin200,
  PostAuthLoginBody,
  PostCustomer200,
  PostCustomerBody,
  PostCustomerFavoriteProductsBody,
  PostCustomerForgotPasswordBody,
  PostCustomerRecentlyViewedProductsBody,
  PostCustomerResetPasswordBody,
  PutCustomerPasswordBody,
} from '../generated.schemas';

import postCustomerMutator from '../../client';
import type {
  BodyType as PostCustomerBodyType,
  ErrorType as PostCustomerErrorType,
} from '../../client';
import getCustomerMutator from '../../client';
import type { ErrorType as GetCustomerErrorType } from '../../client';
import patchCustomerMutator from '../../client';
import type {
  BodyType as PatchCustomerBodyType,
  ErrorType as PatchCustomerErrorType,
} from '../../client';
import deleteCustomerMutator from '../../client';
import type { ErrorType as DeleteCustomerErrorType } from '../../client';
import postCustomerFavoriteProductsMutator from '../../client';
import type {
  BodyType as PostCustomerFavoriteProductsBodyType,
  ErrorType as PostCustomerFavoriteProductsErrorType,
} from '../../client';
import deleteCustomerFavoriteProductsMutator from '../../client';
import type {
  BodyType as DeleteCustomerFavoriteProductsBodyType,
  ErrorType as DeleteCustomerFavoriteProductsErrorType,
} from '../../client';
import postCustomerRecentlyViewedProductsMutator from '../../client';
import type {
  BodyType as PostCustomerRecentlyViewedProductsBodyType,
  ErrorType as PostCustomerRecentlyViewedProductsErrorType,
} from '../../client';
import getCustomerFavoritesMutator from '../../client';
import type { ErrorType as GetCustomerFavoritesErrorType } from '../../client';
import getCustomerCartMutator from '../../client';
import type { ErrorType as GetCustomerCartErrorType } from '../../client';
import putCustomerPasswordMutator from '../../client';
import type {
  BodyType as PutCustomerPasswordBodyType,
  ErrorType as PutCustomerPasswordErrorType,
} from '../../client';
import postCustomerForgotPasswordMutator from '../../client';
import type {
  BodyType as PostCustomerForgotPasswordBodyType,
  ErrorType as PostCustomerForgotPasswordErrorType,
} from '../../client';
import postCustomerResetPasswordMutator from '../../client';
import type {
  BodyType as PostCustomerResetPasswordBodyType,
  ErrorType as PostCustomerResetPasswordErrorType,
} from '../../client';
import postAuthLoginMutator from '../../client';
import type {
  BodyType as PostAuthLoginBodyType,
  ErrorType as PostAuthLoginErrorType,
} from '../../client';

type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1];

/**
 * Recebe os dados de um cliente e cria um novo cliente na loja
 * @summary Cria um cliente
 */
export const postCustomer = (
  postCustomerBody: PostCustomerBodyType<PostCustomerBody>,
  options?: SecondParameter<typeof postCustomerMutator>,
  signal?: AbortSignal,
) => {
  return postCustomerMutator<PostCustomer200>(
    {
      url: '/customer',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postCustomerBody,
      signal,
    },
    options,
  );
};

export const getPostCustomerMutationOptions = <
  TError = PostCustomerErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomer>>,
    TError,
    { data: PostCustomerBodyType<PostCustomerBody> },
    TContext
  >;
  request?: SecondParameter<typeof postCustomerMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCustomer>>,
  TError,
  { data: PostCustomerBodyType<PostCustomerBody> },
  TContext
> => {
  const mutationKey = ['postCustomer'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCustomer>>,
    { data: PostCustomerBodyType<PostCustomerBody> }
  > = (props) => {
    const { data } = props ?? {};

    return postCustomer(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCustomer>>
>;
export type PostCustomerMutationBody = PostCustomerBodyType<PostCustomerBody>;
export type PostCustomerMutationError = PostCustomerErrorType<unknown>;

/**
 * @summary Cria um cliente
 */
export const usePostCustomer = <
  TError = PostCustomerErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomer>>,
    TError,
    { data: PostCustomerBodyType<PostCustomerBody> },
    TContext
  >;
  request?: SecondParameter<typeof postCustomerMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCustomer>>,
  TError,
  { data: PostCustomerBodyType<PostCustomerBody> },
  TContext
> => {
  const mutationOptions = getPostCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Passando um ID de cliente, retorna as informações do cliente
 * @summary Busca cliente
 */
export const getCustomer = (
  options?: SecondParameter<typeof getCustomerMutator>,
  signal?: AbortSignal,
) => {
  return getCustomerMutator<GetCustomer200>(
    { url: '/customer', method: 'GET', signal },
    options,
  );
};

export const getGetCustomerQueryKey = () => {
  return ['/customer'] as const;
};

export const getGetCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = GetCustomerErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>
  >;
  request?: SecondParameter<typeof getCustomerMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomer>>> = ({ signal }) =>
    getCustomer(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomer>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomer>>>;
export type GetCustomerQueryError = GetCustomerErrorType<unknown>;

export function useGetCustomer<
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = GetCustomerErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getCustomer>>,
        TError,
        Awaited<ReturnType<typeof getCustomer>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getCustomerMutator>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCustomer<
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = GetCustomerErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getCustomer>>,
        TError,
        Awaited<ReturnType<typeof getCustomer>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getCustomerMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCustomer<
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = GetCustomerErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>
  >;
  request?: SecondParameter<typeof getCustomerMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Busca cliente
 */

export function useGetCustomer<
  TData = Awaited<ReturnType<typeof getCustomer>>,
  TError = GetCustomerErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>
  >;
  request?: SecondParameter<typeof getCustomerMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetCustomerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Passando um ID de cliente, atualiza as informações do cliente
 * @summary Atualiza cliente
 */
export const patchCustomer = (
  patchCustomerBody: PatchCustomerBodyType<PatchCustomerBody>,
  options?: SecondParameter<typeof patchCustomerMutator>,
) => {
  return patchCustomerMutator<unknown>(
    {
      url: '/customer',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchCustomerBody,
    },
    options,
  );
};

export const getPatchCustomerMutationOptions = <
  TError = PatchCustomerErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchCustomer>>,
    TError,
    { data: PatchCustomerBodyType<PatchCustomerBody> },
    TContext
  >;
  request?: SecondParameter<typeof patchCustomerMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchCustomer>>,
  TError,
  { data: PatchCustomerBodyType<PatchCustomerBody> },
  TContext
> => {
  const mutationKey = ['patchCustomer'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchCustomer>>,
    { data: PatchCustomerBodyType<PatchCustomerBody> }
  > = (props) => {
    const { data } = props ?? {};

    return patchCustomer(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchCustomer>>
>;
export type PatchCustomerMutationBody = PatchCustomerBodyType<PatchCustomerBody>;
export type PatchCustomerMutationError = PatchCustomerErrorType<unknown>;

/**
 * @summary Atualiza cliente
 */
export const usePatchCustomer = <
  TError = PatchCustomerErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchCustomer>>,
    TError,
    { data: PatchCustomerBodyType<PatchCustomerBody> },
    TContext
  >;
  request?: SecondParameter<typeof patchCustomerMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchCustomer>>,
  TError,
  { data: PatchCustomerBodyType<PatchCustomerBody> },
  TContext
> => {
  const mutationOptions = getPatchCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Passando um ID de cliente, deleta o cliente da loja
 * @summary Deleta um cliente
 */
export const deleteCustomer = (
  options?: SecondParameter<typeof deleteCustomerMutator>,
) => {
  return deleteCustomerMutator<unknown>({ url: '/customer', method: 'DELETE' }, options);
};

export const getDeleteCustomerMutationOptions = <
  TError = DeleteCustomerErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCustomer>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof deleteCustomerMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCustomer>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['deleteCustomer'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCustomer>>,
    void
  > = () => {
    return deleteCustomer(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCustomer>>
>;

export type DeleteCustomerMutationError = DeleteCustomerErrorType<unknown>;

/**
 * @summary Deleta um cliente
 */
export const useDeleteCustomer = <
  TError = DeleteCustomerErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCustomer>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof deleteCustomerMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCustomer>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getDeleteCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Passando um IDs dos produtos, adiciona os produtos favoritos do cliente
 * @summary Adiciona produtos aos favoritos do cliente
 */
export const postCustomerFavoriteProducts = (
  postCustomerFavoriteProductsBody: PostCustomerFavoriteProductsBodyType<PostCustomerFavoriteProductsBody>,
  options?: SecondParameter<typeof postCustomerFavoriteProductsMutator>,
  signal?: AbortSignal,
) => {
  return postCustomerFavoriteProductsMutator<unknown>(
    {
      url: '/customer/favorite-products',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postCustomerFavoriteProductsBody,
      signal,
    },
    options,
  );
};

export const getPostCustomerFavoriteProductsMutationOptions = <
  TError = PostCustomerFavoriteProductsErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerFavoriteProducts>>,
    TError,
    { data: PostCustomerFavoriteProductsBodyType<PostCustomerFavoriteProductsBody> },
    TContext
  >;
  request?: SecondParameter<typeof postCustomerFavoriteProductsMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCustomerFavoriteProducts>>,
  TError,
  { data: PostCustomerFavoriteProductsBodyType<PostCustomerFavoriteProductsBody> },
  TContext
> => {
  const mutationKey = ['postCustomerFavoriteProducts'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCustomerFavoriteProducts>>,
    { data: PostCustomerFavoriteProductsBodyType<PostCustomerFavoriteProductsBody> }
  > = (props) => {
    const { data } = props ?? {};

    return postCustomerFavoriteProducts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCustomerFavoriteProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCustomerFavoriteProducts>>
>;
export type PostCustomerFavoriteProductsMutationBody =
  PostCustomerFavoriteProductsBodyType<PostCustomerFavoriteProductsBody>;
export type PostCustomerFavoriteProductsMutationError =
  PostCustomerFavoriteProductsErrorType<unknown>;

/**
 * @summary Adiciona produtos aos favoritos do cliente
 */
export const usePostCustomerFavoriteProducts = <
  TError = PostCustomerFavoriteProductsErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerFavoriteProducts>>,
    TError,
    { data: PostCustomerFavoriteProductsBodyType<PostCustomerFavoriteProductsBody> },
    TContext
  >;
  request?: SecondParameter<typeof postCustomerFavoriteProductsMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCustomerFavoriteProducts>>,
  TError,
  { data: PostCustomerFavoriteProductsBodyType<PostCustomerFavoriteProductsBody> },
  TContext
> => {
  const mutationOptions = getPostCustomerFavoriteProductsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Passando um IDs dos produtos, remove os produtos favoritos do cliente
 * @summary Remove produtos dos favoritos do cliente
 */
export const deleteCustomerFavoriteProducts = (
  deleteCustomerFavoriteProductsBody: DeleteCustomerFavoriteProductsBodyType<DeleteCustomerFavoriteProductsBody>,
  options?: SecondParameter<typeof deleteCustomerFavoriteProductsMutator>,
) => {
  return deleteCustomerFavoriteProductsMutator<unknown>(
    {
      url: '/customer/favorite-products',
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: deleteCustomerFavoriteProductsBody,
    },
    options,
  );
};

export const getDeleteCustomerFavoriteProductsMutationOptions = <
  TError = DeleteCustomerFavoriteProductsErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCustomerFavoriteProducts>>,
    TError,
    { data: DeleteCustomerFavoriteProductsBodyType<DeleteCustomerFavoriteProductsBody> },
    TContext
  >;
  request?: SecondParameter<typeof deleteCustomerFavoriteProductsMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCustomerFavoriteProducts>>,
  TError,
  { data: DeleteCustomerFavoriteProductsBodyType<DeleteCustomerFavoriteProductsBody> },
  TContext
> => {
  const mutationKey = ['deleteCustomerFavoriteProducts'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCustomerFavoriteProducts>>,
    { data: DeleteCustomerFavoriteProductsBodyType<DeleteCustomerFavoriteProductsBody> }
  > = (props) => {
    const { data } = props ?? {};

    return deleteCustomerFavoriteProducts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCustomerFavoriteProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCustomerFavoriteProducts>>
>;
export type DeleteCustomerFavoriteProductsMutationBody =
  DeleteCustomerFavoriteProductsBodyType<DeleteCustomerFavoriteProductsBody>;
export type DeleteCustomerFavoriteProductsMutationError =
  DeleteCustomerFavoriteProductsErrorType<unknown>;

/**
 * @summary Remove produtos dos favoritos do cliente
 */
export const useDeleteCustomerFavoriteProducts = <
  TError = DeleteCustomerFavoriteProductsErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCustomerFavoriteProducts>>,
    TError,
    { data: DeleteCustomerFavoriteProductsBodyType<DeleteCustomerFavoriteProductsBody> },
    TContext
  >;
  request?: SecondParameter<typeof deleteCustomerFavoriteProductsMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteCustomerFavoriteProducts>>,
  TError,
  { data: DeleteCustomerFavoriteProductsBodyType<DeleteCustomerFavoriteProductsBody> },
  TContext
> => {
  const mutationOptions = getDeleteCustomerFavoriteProductsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Passando um IDs dos produtos, adiciona os produtos recentemente vistos do cliente
 * @summary Adiciona produtos aos recentemente vistos do cliente
 */
export const postCustomerRecentlyViewedProducts = (
  postCustomerRecentlyViewedProductsBody: PostCustomerRecentlyViewedProductsBodyType<PostCustomerRecentlyViewedProductsBody>,
  options?: SecondParameter<typeof postCustomerRecentlyViewedProductsMutator>,
  signal?: AbortSignal,
) => {
  return postCustomerRecentlyViewedProductsMutator<unknown>(
    {
      url: '/customer/recently-viewed-products',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postCustomerRecentlyViewedProductsBody,
      signal,
    },
    options,
  );
};

export const getPostCustomerRecentlyViewedProductsMutationOptions = <
  TError = PostCustomerRecentlyViewedProductsErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerRecentlyViewedProducts>>,
    TError,
    {
      data: PostCustomerRecentlyViewedProductsBodyType<PostCustomerRecentlyViewedProductsBody>;
    },
    TContext
  >;
  request?: SecondParameter<typeof postCustomerRecentlyViewedProductsMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCustomerRecentlyViewedProducts>>,
  TError,
  {
    data: PostCustomerRecentlyViewedProductsBodyType<PostCustomerRecentlyViewedProductsBody>;
  },
  TContext
> => {
  const mutationKey = ['postCustomerRecentlyViewedProducts'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCustomerRecentlyViewedProducts>>,
    {
      data: PostCustomerRecentlyViewedProductsBodyType<PostCustomerRecentlyViewedProductsBody>;
    }
  > = (props) => {
    const { data } = props ?? {};

    return postCustomerRecentlyViewedProducts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCustomerRecentlyViewedProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCustomerRecentlyViewedProducts>>
>;
export type PostCustomerRecentlyViewedProductsMutationBody =
  PostCustomerRecentlyViewedProductsBodyType<PostCustomerRecentlyViewedProductsBody>;
export type PostCustomerRecentlyViewedProductsMutationError =
  PostCustomerRecentlyViewedProductsErrorType<unknown>;

/**
 * @summary Adiciona produtos aos recentemente vistos do cliente
 */
export const usePostCustomerRecentlyViewedProducts = <
  TError = PostCustomerRecentlyViewedProductsErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerRecentlyViewedProducts>>,
    TError,
    {
      data: PostCustomerRecentlyViewedProductsBodyType<PostCustomerRecentlyViewedProductsBody>;
    },
    TContext
  >;
  request?: SecondParameter<typeof postCustomerRecentlyViewedProductsMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCustomerRecentlyViewedProducts>>,
  TError,
  {
    data: PostCustomerRecentlyViewedProductsBodyType<PostCustomerRecentlyViewedProductsBody>;
  },
  TContext
> => {
  const mutationOptions = getPostCustomerRecentlyViewedProductsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retorna os produtos favoritos do cliente
 * @summary Busca os produtos favoritos do cliente
 */
export const getCustomerFavorites = (
  options?: SecondParameter<typeof getCustomerFavoritesMutator>,
  signal?: AbortSignal,
) => {
  return getCustomerFavoritesMutator<GetCustomerFavorites200Item[]>(
    { url: '/customer/favorites', method: 'GET', signal },
    options,
  );
};

export const getGetCustomerFavoritesQueryKey = () => {
  return ['/customer/favorites'] as const;
};

export const getGetCustomerFavoritesQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerFavorites>>,
  TError = GetCustomerFavoritesErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomerFavorites>>, TError, TData>
  >;
  request?: SecondParameter<typeof getCustomerFavoritesMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerFavoritesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerFavorites>>> = ({
    signal,
  }) => getCustomerFavorites(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerFavorites>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetCustomerFavoritesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerFavorites>>
>;
export type GetCustomerFavoritesQueryError = GetCustomerFavoritesErrorType<unknown>;

export function useGetCustomerFavorites<
  TData = Awaited<ReturnType<typeof getCustomerFavorites>>,
  TError = GetCustomerFavoritesErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomerFavorites>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getCustomerFavorites>>,
        TError,
        Awaited<ReturnType<typeof getCustomerFavorites>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getCustomerFavoritesMutator>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCustomerFavorites<
  TData = Awaited<ReturnType<typeof getCustomerFavorites>>,
  TError = GetCustomerFavoritesErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomerFavorites>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getCustomerFavorites>>,
        TError,
        Awaited<ReturnType<typeof getCustomerFavorites>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getCustomerFavoritesMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCustomerFavorites<
  TData = Awaited<ReturnType<typeof getCustomerFavorites>>,
  TError = GetCustomerFavoritesErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomerFavorites>>, TError, TData>
  >;
  request?: SecondParameter<typeof getCustomerFavoritesMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Busca os produtos favoritos do cliente
 */

export function useGetCustomerFavorites<
  TData = Awaited<ReturnType<typeof getCustomerFavorites>>,
  TError = GetCustomerFavoritesErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getCustomerFavorites>>, TError, TData>
  >;
  request?: SecondParameter<typeof getCustomerFavoritesMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetCustomerFavoritesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Busca o carrinho do cliente
 * @summary Busca o carrinho do cliente
 */
export const getCustomerCart = (
  params: GetCustomerCartParams,
  options?: SecondParameter<typeof getCustomerCartMutator>,
  signal?: AbortSignal,
) => {
  return getCustomerCartMutator<GetCustomerCart200>(
    { url: '/customer/cart', method: 'GET', params, signal },
    options,
  );
};

export const getGetCustomerCartQueryKey = (params: GetCustomerCartParams) => {
  return ['/customer/cart', ...(params ? [params] : [])] as const;
};

export const getGetCustomerCartQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerCart>>,
  TError = GetCustomerCartErrorType<unknown>,
>(
  params: GetCustomerCartParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerCart>>, TError, TData>
    >;
    request?: SecondParameter<typeof getCustomerCartMutator>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerCartQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerCart>>> = ({
    signal,
  }) => getCustomerCart(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerCart>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetCustomerCartQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerCart>>
>;
export type GetCustomerCartQueryError = GetCustomerCartErrorType<unknown>;

export function useGetCustomerCart<
  TData = Awaited<ReturnType<typeof getCustomerCart>>,
  TError = GetCustomerCartErrorType<unknown>,
>(
  params: GetCustomerCartParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerCart>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustomerCart>>,
          TError,
          Awaited<ReturnType<typeof getCustomerCart>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof getCustomerCartMutator>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCustomerCart<
  TData = Awaited<ReturnType<typeof getCustomerCart>>,
  TError = GetCustomerCartErrorType<unknown>,
>(
  params: GetCustomerCartParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerCart>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustomerCart>>,
          TError,
          Awaited<ReturnType<typeof getCustomerCart>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof getCustomerCartMutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetCustomerCart<
  TData = Awaited<ReturnType<typeof getCustomerCart>>,
  TError = GetCustomerCartErrorType<unknown>,
>(
  params: GetCustomerCartParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerCart>>, TError, TData>
    >;
    request?: SecondParameter<typeof getCustomerCartMutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Busca o carrinho do cliente
 */

export function useGetCustomerCart<
  TData = Awaited<ReturnType<typeof getCustomerCart>>,
  TError = GetCustomerCartErrorType<unknown>,
>(
  params: GetCustomerCartParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getCustomerCart>>, TError, TData>
    >;
    request?: SecondParameter<typeof getCustomerCartMutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetCustomerCartQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Passando um ID de cliente, atualiza a senha do cliente
 * @summary Atualiza senha do cliente
 */
export const putCustomerPassword = (
  putCustomerPasswordBody: PutCustomerPasswordBodyType<PutCustomerPasswordBody>,
  options?: SecondParameter<typeof putCustomerPasswordMutator>,
) => {
  return putCustomerPasswordMutator<unknown>(
    {
      url: '/customer/password',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: putCustomerPasswordBody,
    },
    options,
  );
};

export const getPutCustomerPasswordMutationOptions = <
  TError = PutCustomerPasswordErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCustomerPassword>>,
    TError,
    { data: PutCustomerPasswordBodyType<PutCustomerPasswordBody> },
    TContext
  >;
  request?: SecondParameter<typeof putCustomerPasswordMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCustomerPassword>>,
  TError,
  { data: PutCustomerPasswordBodyType<PutCustomerPasswordBody> },
  TContext
> => {
  const mutationKey = ['putCustomerPassword'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCustomerPassword>>,
    { data: PutCustomerPasswordBodyType<PutCustomerPasswordBody> }
  > = (props) => {
    const { data } = props ?? {};

    return putCustomerPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCustomerPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCustomerPassword>>
>;
export type PutCustomerPasswordMutationBody =
  PutCustomerPasswordBodyType<PutCustomerPasswordBody>;
export type PutCustomerPasswordMutationError = PutCustomerPasswordErrorType<unknown>;

/**
 * @summary Atualiza senha do cliente
 */
export const usePutCustomerPassword = <
  TError = PutCustomerPasswordErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCustomerPassword>>,
    TError,
    { data: PutCustomerPasswordBodyType<PutCustomerPasswordBody> },
    TContext
  >;
  request?: SecondParameter<typeof putCustomerPasswordMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCustomerPassword>>,
  TError,
  { data: PutCustomerPasswordBodyType<PutCustomerPasswordBody> },
  TContext
> => {
  const mutationOptions = getPutCustomerPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Passando um email, envia um token por email para redefinir a senha
 * @summary Esqueci minha senha
 */
export const postCustomerForgotPassword = (
  postCustomerForgotPasswordBody: PostCustomerForgotPasswordBodyType<PostCustomerForgotPasswordBody>,
  options?: SecondParameter<typeof postCustomerForgotPasswordMutator>,
  signal?: AbortSignal,
) => {
  return postCustomerForgotPasswordMutator<unknown>(
    {
      url: '/customer/forgot-password',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postCustomerForgotPasswordBody,
      signal,
    },
    options,
  );
};

export const getPostCustomerForgotPasswordMutationOptions = <
  TError = PostCustomerForgotPasswordErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerForgotPassword>>,
    TError,
    { data: PostCustomerForgotPasswordBodyType<PostCustomerForgotPasswordBody> },
    TContext
  >;
  request?: SecondParameter<typeof postCustomerForgotPasswordMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCustomerForgotPassword>>,
  TError,
  { data: PostCustomerForgotPasswordBodyType<PostCustomerForgotPasswordBody> },
  TContext
> => {
  const mutationKey = ['postCustomerForgotPassword'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCustomerForgotPassword>>,
    { data: PostCustomerForgotPasswordBodyType<PostCustomerForgotPasswordBody> }
  > = (props) => {
    const { data } = props ?? {};

    return postCustomerForgotPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCustomerForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCustomerForgotPassword>>
>;
export type PostCustomerForgotPasswordMutationBody =
  PostCustomerForgotPasswordBodyType<PostCustomerForgotPasswordBody>;
export type PostCustomerForgotPasswordMutationError =
  PostCustomerForgotPasswordErrorType<unknown>;

/**
 * @summary Esqueci minha senha
 */
export const usePostCustomerForgotPassword = <
  TError = PostCustomerForgotPasswordErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerForgotPassword>>,
    TError,
    { data: PostCustomerForgotPasswordBodyType<PostCustomerForgotPasswordBody> },
    TContext
  >;
  request?: SecondParameter<typeof postCustomerForgotPasswordMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCustomerForgotPassword>>,
  TError,
  { data: PostCustomerForgotPasswordBodyType<PostCustomerForgotPasswordBody> },
  TContext
> => {
  const mutationOptions = getPostCustomerForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Passando um email, token de recuperação e nova senha, atualiza a senha do usuário
 * @summary Criar nova senha
 */
export const postCustomerResetPassword = (
  postCustomerResetPasswordBody: PostCustomerResetPasswordBodyType<PostCustomerResetPasswordBody>,
  options?: SecondParameter<typeof postCustomerResetPasswordMutator>,
  signal?: AbortSignal,
) => {
  return postCustomerResetPasswordMutator<unknown>(
    {
      url: '/customer/reset-password',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postCustomerResetPasswordBody,
      signal,
    },
    options,
  );
};

export const getPostCustomerResetPasswordMutationOptions = <
  TError = PostCustomerResetPasswordErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerResetPassword>>,
    TError,
    { data: PostCustomerResetPasswordBodyType<PostCustomerResetPasswordBody> },
    TContext
  >;
  request?: SecondParameter<typeof postCustomerResetPasswordMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCustomerResetPassword>>,
  TError,
  { data: PostCustomerResetPasswordBodyType<PostCustomerResetPasswordBody> },
  TContext
> => {
  const mutationKey = ['postCustomerResetPassword'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCustomerResetPassword>>,
    { data: PostCustomerResetPasswordBodyType<PostCustomerResetPasswordBody> }
  > = (props) => {
    const { data } = props ?? {};

    return postCustomerResetPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCustomerResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCustomerResetPassword>>
>;
export type PostCustomerResetPasswordMutationBody =
  PostCustomerResetPasswordBodyType<PostCustomerResetPasswordBody>;
export type PostCustomerResetPasswordMutationError =
  PostCustomerResetPasswordErrorType<unknown>;

/**
 * @summary Criar nova senha
 */
export const usePostCustomerResetPassword = <
  TError = PostCustomerResetPasswordErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCustomerResetPassword>>,
    TError,
    { data: PostCustomerResetPasswordBodyType<PostCustomerResetPasswordBody> },
    TContext
  >;
  request?: SecondParameter<typeof postCustomerResetPasswordMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCustomerResetPassword>>,
  TError,
  { data: PostCustomerResetPasswordBodyType<PostCustomerResetPasswordBody> },
  TContext
> => {
  const mutationOptions = getPostCustomerResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Passando um email e senha, retorna um token de autenticação
 * @summary Autenticação
 */
export const postAuthLogin = (
  postAuthLoginBody: PostAuthLoginBodyType<PostAuthLoginBody>,
  options?: SecondParameter<typeof postAuthLoginMutator>,
  signal?: AbortSignal,
) => {
  return postAuthLoginMutator<PostAuthLogin200>(
    {
      url: '/auth/login',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postAuthLoginBody,
      signal,
    },
    options,
  );
};

export const getPostAuthLoginMutationOptions = <
  TError = PostAuthLoginErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthLogin>>,
    TError,
    { data: PostAuthLoginBodyType<PostAuthLoginBody> },
    TContext
  >;
  request?: SecondParameter<typeof postAuthLoginMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthLogin>>,
  TError,
  { data: PostAuthLoginBodyType<PostAuthLoginBody> },
  TContext
> => {
  const mutationKey = ['postAuthLogin'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthLogin>>,
    { data: PostAuthLoginBodyType<PostAuthLoginBody> }
  > = (props) => {
    const { data } = props ?? {};

    return postAuthLogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthLogin>>
>;
export type PostAuthLoginMutationBody = PostAuthLoginBodyType<PostAuthLoginBody>;
export type PostAuthLoginMutationError = PostAuthLoginErrorType<unknown>;

/**
 * @summary Autenticação
 */
export const usePostAuthLogin = <
  TError = PostAuthLoginErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthLogin>>,
    TError,
    { data: PostAuthLoginBodyType<PostAuthLoginBody> },
    TContext
  >;
  request?: SecondParameter<typeof postAuthLoginMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAuthLogin>>,
  TError,
  { data: PostAuthLoginBodyType<PostAuthLoginBody> },
  TContext
> => {
  const mutationOptions = getPostAuthLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
